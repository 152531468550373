.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh;  */
}

#contactInfo {
    width: 70vmax;
    padding: 2%;
    background-color: #f9f9f9; 
    border: 1px solid #5C5470;
    border-radius: 10px; 
}

.title {
    color: #352F44;
}

.submit-btn {
    background-color: #5C5470 !important;   
}

.submit-btn:hover {
    opacity: 70%;
}
